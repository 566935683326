.App {
  display: flex;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.Top {
  display: absolute;
  flex: none;
  max-height: 20vh;
  pointer-events: none;
  min-height: 20vh;
}

.Header {
  display: absolute;
  flex: none;
  align-items: center;
  justify-content: center;
  font-size: calc(22px + 2vmin);
  color: white;
  font-family: "Alien Encounters";
  z-index: 1;
  margin-bottom: 2vh;
}

.Body {
  display: flex;
  flex-direction: column;
  flex: 6;
  align-items: center;
  justify-content: start;
  font-size: calc(20px + 1vmin);
  color: white;
  font-family: "Alien Encounters";
  z-index: 1;
}

.Footer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.selectable-option {
  display: flex;
  flex-direction: row;
}
.selectable-option:hover {
  cursor: pointer;
  color: rgba(239, 247, 4, 0.831);
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'Alien Encounters';
  src: url('./assets/alien-encounters-bold-italic-webfont.woff2') format('woff2'),
       url('./assets/alien-encounters-bold-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, #app, #app>div {
  height: 100%;
}

.about-body {
  display: flex;
  flex: 10;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
}