.CardHeader {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: rgba(239, 247, 4, 0.831);
    font-family: "Alien Encounters";
}

.CardContent {
    width: 370;
    background-color: rgba(99, 95, 85, 0.1);
    margin-bottom: 5;
}

.Card {
    font-family: "monospace";
}

@font-face {
    font-family: 'Alien Encounters';
    src: url('./assets/alien-encounters-bold-italic-webfont.woff2') format('woff2'),
         url('./assets/alien-encounters-bold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }